import * as React from "react"
import { Helmet } from 'react-helmet'
import logo from '../images/liberarealestate.png'
import llamada from '../images/llamada.png'
import whatsapp from '../images/whatsapp.png'
import email from '../images/email.png'

// markup
const IndexPage = () => {
  return (
    <div>
      <Helmet>
      <meta name="title" content="XOMAK - Departamentos tipo estudio, Tulum - Quintana Roo"/>
      <meta name="keywords"  content="compra, tulum, cancun, quintana roo, departamentos"/>
      <meta property="og:title" content="Xomak Tulum"/>
      <meta property="og:image" content="https://xomaktulum.com/xomak/xomaktulum1.jpg" />
      <meta property="og:url" content="https://xomaktulum.com/"/>
      <meta property="og:description" content="Departamentos tipo estudio, estilo boho chic y con lujosos acabados pensados para ti que buscas comodclassNamead y sofisticación en el caribe"/>
      <link rel="shortcut icon" href="images/isotipo.png"/>
      <link rel="apple-touch-icon" href="images/isotipo.png"/>
      <link rel="apple-touch-icon" sizes="72x72" href="xomak/isotipo.png"/>
      <link rel="apple-touch-icon" sizes="114x114" href="xomak/isotipo.png"/>
      <link rel="canonical" href="https://xomaktulum.com" />
    </Helmet>
    <div className="imagen01"></div>
    <div className="imagen02"></div>
    <div className="imagen03"></div>
    <div className="imagen04"></div>
    <div className="imagen05"></div>
    <div className="imagen06"></div>
    <div className="imagen07"></div>
    <div className="imagen08"></div>
    <div className="imagen09"></div>
    <div className="imagen010"></div>
    <div className="imagen11"></div>    
    <div className="imagen12"></div>
<iframe className="iframediv"  scrolling="no"
src="https://crmglibera.com/sistemas_active/crm/if/indexBase.php?clvde=jm3lIbfJj9D73"></iframe>
<div style={{width:'100%'}}>
<p>CONTACTO</p>
</div>
<div className="divFooter">

<div className="footer">
<a href="tel:9995188380"><img src={llamada} width='36px' /></a>	
<a href="https://wa.me/529994352736"><img src={whatsapp} width='36px' /></a>	
<a href="mailto:contacto@liberarealestate.com"><img src={email} width='36px' /></a>	<br/>
	<p>Libera Real Estate</p>
	<p>Tel. 999 518 83 80</p>
	<p>Email: contacto@liberarealestate.com</p>
</div>

<div className="footer" style={{paddingTop:62}}>
<img src={logo} width='200px' />
</div>

<div className="footer" style={{paddingTop:105}}>
<p><a href="https://liberarealestate.com/">www.liberarealestate.com</a></p>
	<p>Avenida Garcia Lavín <br/>  Oficina Nivel 5. Skycity,<br/> Mérida Yucatán.</p>
</div>

</div>

    </div>
  )
}
export default IndexPage
